













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";

import { setRoute } from "@/services/route";
import { createRequest } from "@/services/api-connector";
import eventsRegister from "@/services/events-register";
import { opentParcelCargoCard } from "@/services/model";
import { mapGetters, mapMutations } from "vuex";

class LogMsg {
  id: number;

  F: number;
  T: string; // packages
  sKEY: string;
  Class_id: number;
  bounesValue: number;
  bonusType: number;
  value: number | undefined;

  objId: number;
  text: string; // Note or Msg
  addInfo: string;
  dateFormated: string; // 27/11
  date: string; // 2019-11-27T12:30:31 DT_UTC or DT_Reg_UTC
  isRead: boolean;

  // [{DT_UTC: "2019-12-23T10:57:58", Note: "Посилка прийнята на складі відправлення", sKEY: "2658747", T: "packages", F: 3, OBJECT_ID: 2658747},...]
  constructor(eventData: any, lastViewDateStr: string, readMsgList: string[]) {
    // constructor(id: number, objId: number, text: string, dateStr: string, lastViewDateStr: string) {
    this.F = eventData.F;
    this.T = eventData.T;
    this.sKEY = eventData.sKEY;
    this.Class_id = eventData.Class_id;
    this.bounesValue = parseFloat(eventData.AddInfo) || 0;
    this.bonusType = eventData.TypeDoc_id || 0; // 11760 - refferal 11761 - affiliate
    if (eventData.F === 1) this.value = parseFloat(eventData.Note); // convert string to float for finance type

    this.id = Date.now() + Math.floor(Math.random() * Math.floor(100000)); // generate id

    this.objId = eventData.OBJECT_ID;
    this.text = eventData.Note;
    this.addInfo = eventData.AddInfo;
    this.date = eventData.DT_UTC;

    // set date
    const dDB = new Date(eventData.DT_UTC); // create database dateUTC Mon Aug 12 2019 09:53:07 GMT+0300
    const dateUTC = Date.UTC(
      dDB.getFullYear(),
      dDB.getMonth(),
      dDB.getDate(),
      dDB.getHours(),
      dDB.getMinutes(),
      dDB.getSeconds()
    ); // 1565603587000
    // or return miliseconds: return dateUTC
    const dateLogItem = new Date(dateUTC); // Mon Aug 12 2019 12:53:07 GMT+0300
    const month = dateLogItem.getMonth() + 1;
    const hours = ("0" + dateLogItem.getHours()).slice(-2);
    const minutes = ("0" + dateLogItem.getMinutes()).slice(-2);
    this.dateFormated =
      "" +
      dateLogItem.getDate() +
      "." +
      ("0" + month).slice(-2) +
      "." +
      dateLogItem.getFullYear() +
      " " +
      hours +
      ":" +
      minutes;

    // check new logEvent (isRead)
    this.isRead = true;
    if (eventData.F === 6) {
      // shopping chat message
      const isRead = readMsgList.includes(eventData.date);
      if (!eventData.DT_View_UTC && !isRead) this.isRead = false;
    } else if (lastViewDateStr) {
      // other log messages
      const lastViewDate = new Date(lastViewDateStr);
      if (dateLogItem > lastViewDate) this.isRead = false;
    }
  }
}

export default Vue.extend({
  data() {
    return {
      eventsRegister,
      setRoute,
      opentParcelCargoCard,

      msgList: [] as LogMsg[],
      msgListToday: [] as LogMsg[],
      msgListYesterday: [] as LogMsg[],
      readMsgList:
        JSON.parse(`${window.localStorage.getItem(`readMsgs`)}`) || [],

      isArchiveLoaded: false,
    };
  },

  created() {
    // Update msgList on messages changed
    this.$watch("logList", this.distributeMessages);
  },

  beforeMount() {
    this.distributeMessages();
  },

  mounted() {
    // save events view date to userData DB
    const dateNow = new Date();
    const requestData: any = {
      tmp_object_list: [
        {
          OBJECT_ID: this.userId,
          Class_id: 22100,
          ObjectDB_id: this.userId,
        },
      ],
      tmp_object_property_scalar: [
        {
          OBJECT_ID: this.userId,
          Property_id: 568,
          VALUE: dateNow.toJSON(),
          Oper: "U",
        },
      ],
    };
    createRequest(
      "objEdit",
      requestData,
      () => {
        // {"message": "Операція успішна","status": "Success"}}
        this.$root.$emit(eventsRegister.updateUserData); // update user data
      },
      () => {
        console.warn("Error save Events view date");
      } // error callback
    );
  },

  computed: {
    ...mapGetters("user", ["user", "userId", "logList"]),
    isEmpty(): boolean {
      return (
        !this.msgListToday.length &&
        !this.msgListYesterday.length &&
        !this.msgList.length
      );
    },
  },

  methods: {
    ...mapMutations("root", ["addOpenMessage"]),
    distributeMessages() {
      // animate parcels on init loading data
      this.msgList = [];
      this.msgListToday = [];
      this.msgListYesterday = [];

      let msgObj: LogMsg;
      this.logList.forEach((msgItem: any) => {
        msgObj = new LogMsg(msgItem, this.user.pr568, this.readMsgList);

        const todayDate = new Date();
        const dateLogItem = new Date(msgItem.DT_UTC);
        // Check today log event
        if (
          dateLogItem.getFullYear() === todayDate.getUTCFullYear() &&
          dateLogItem.getMonth() === todayDate.getUTCMonth() &&
          dateLogItem.getDate() === todayDate.getUTCDate()
        ) {
          this.msgListToday.push(msgObj);
          // Check yesterday log event
        } else if (
          dateLogItem.getFullYear() === todayDate.getUTCFullYear() &&
          dateLogItem.getMonth() === todayDate.getUTCMonth() &&
          dateLogItem.getDate() - 1 === todayDate.getUTCDate() - 1
        ) {
          this.msgListYesterday.push(msgObj);
        } // other messages
        else this.msgList.push(msgObj);
      });
    },

    // get popup title from shopping data
    openShopping(parcelId: string) {
      this.$router.push({
        name: "ParcelInfo",
        params: { parcelId },
      });
    },
    // get popup title from parcel data
    openParcel(parcelId: string) {
      this.$router.push({
        name: "ParcelInfo",
        params: { parcelId },
      });
    },

    openMessagePopup(objId: number, orderType: string): void {
      this.addOpenMessage([objId]);
      this.$root.$emit(eventsRegister.showPopup, {
        body: { objId: objId, type: orderType },
        header: "",
        type: "message",
      });
    },

    openNoParcelPopup(msg: LogMsg): void {
      msg.isRead = true;

      window.localStorage.setItem(
        `readMsgs`,
        JSON.stringify([...this.readMsgList, msg.date])
      );
      this.addOpenMessage([msg.date]);
      this.$root.$emit(eventsRegister.showPopup, {
        body: {
          title: msg.addInfo,
          text: msg.text,
        },
        header: "",
        type: "info",
      });
    },

    openBonusPopup({ bonusType, value }: any) {
      this.$root.$emit(eventsRegister.showPopup, {
        body: {
          bonusType,
          value,
        },
        header: "",
        type: "bonus",
      });
    },

    loadArchive() {
      if (this.isArchiveLoaded) return false;
      this.$root.$emit("updateLastUpdatesArchive");
      this.$root.$emit("updateParcelsArchive");
      this.isArchiveLoaded = true;
    },
  },
});
