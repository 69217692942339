/* eslint-disable */

import { appData } from '../store/appdata'
import i18n from '@/i18n'
import { getObjInAr } from '../utils/utils'
import eventsRegister from '@/services/events-register'


export function getMetaName(metaObjectId: number): string {
    let currentMetaObjectLabel: any = {},
        i = 0,
        data = appData.metadata.md_metaobject_label,
        dataLen = appData.metadata.md_metaobject_label.length
    for (; i < dataLen; i++) {
        currentMetaObjectLabel = data[i]
        if (currentMetaObjectLabel.MetaObject_id === metaObjectId)              
            return currentMetaObjectLabel.Name
    }
    return ''
}

export class SysType {
    id: number
    name: string

    constructor(id: number){
        this.id = id
        this.name = getMetaName(id) 
    }
}

export class SysDir {
    id: number
    sysTypesList: Array<SysType> = []

    constructor(sysDirId: number) {
        this.id = sysDirId
        appData.metadata.md_sys_type.forEach((sysItem: any)=>{
            if (sysItem.SysDir_id === sysDirId)
                this.sysTypesList.push( new SysType( sysItem.SysType_id ) )
        })
    }
}

export interface SysTypeI {
    'metaObject_id': number
    'name': string
}

export class Contact {
    contactId = -1
    firstName = '' // async
    lastName = '' // async
    phoneNumber = '' // async
    phoneNumberId!: number // object_id: 1691432, pr_309: 164932, pr_337: -11009, pr_389: "0937874104", pr_409: false, pr_590: null
 // object_id: 1691432, pr_309: 164932, pr_337: -11009, pr_389: "0937874104", pr_409: false, pr_590: null
    fetchObjectsListWrap: any

    constructor(contactId: number, fetchObjectsListWrap: any) {
        this.contactId = contactId
        this.fetchObjectsListWrap = fetchObjectsListWrap

        this.getContactDetail()
    }

    getContactDetail(callback?: any) {
        // Fetch data if data is not exist 
        if (!this.firstName || !this.lastName || !this.phoneNumber) {
            let contactRequestData = {
                "ClassList": [{"Class_id": -21210,"TypeLoad":0}],
                "PropertyList": [
                    {"Class_id": -21210, "Property_id": -232},
                    {"Class_id": -21210, "Property_id": -225},
                    {"Class_id": -21210, "Property_id": -408}
                ],
                "Filter": [
                    { "Class_id": -21210,"Filter_id":1,"Node_id":1,"Property_id":-1,"Operator_id":"=","Value": this.contactId }
                ],
                "PropertyDetailFilter": [
                    { "Class_id": -21210,"Property_id": -408,"PropertyDetail_id": -337,"Operator_id":"=","Value": -11009 }
                ]
            }
            this.fetchObjectsListWrap(
                contactRequestData,
                (contactResponseData: any)=>{ // success func contacts request
                    if (contactResponseData['N1_21210__1'][0]) {                        
                        this.firstName = contactResponseData['N1_21210__1'][0].pr_232
                        this.lastName = contactResponseData['N1_21210__1'][0].pr_225
                        this.phoneNumber = contactResponseData['N3_21210__408'][0].pr_389
                        this.phoneNumberId = contactResponseData['N3_21210__408'][0].pr_309
                        if (callback) callback({ firstName: this.firstName, lastName: this.lastName, phoneNumber: this.phoneNumber})
                    } else console.log('Error get address contact '+ this.contactId)
                }
            )
        } else { // return existed data
            if (callback) callback({ firstName: this.firstName, lastName: this.lastName, phoneNumber: this.phoneNumber})
        }
    }
}


// CityAddress
export class CityAddress { // TODO: get country after region
    id = 0
    title = '' // address name
    zipCode = ''

    cityName = ''
    cityId = -1
    type = -1
    contact!: Contact
    regionName = ''
    regionId = -1
    isDefault = false

    addressData: any
    addressDataNames: any
    fetchObjectsListWrap: any

    constructor(addressData: any, addressDataNames: Array<any>, fetchObjectsListWrap: any) {
        this.addressData = addressData
        this.addressDataNames = addressDataNames
        this.fetchObjectsListWrap = fetchObjectsListWrap
        this.id = addressData.pr_309
        this.title = addressData.pr_222
        this.zipCode = addressData.pr_256
        this.type = addressData.pr229
        if (addressData.pr_298) this.isDefault = true
        if (addressData.pr364) {
            this.cityId = addressData.pr364
            let cityNameObj = getObjInAr(addressDataNames, 'Object_id', this.cityId)
            this.cityName = cityNameObj.CalcName
        } else 
            console.log('Warn: City is null')

        // Create Contact. Optional, only if exist
        if (addressData.pr_471)
            this.contact = new Contact(addressData.pr_471, fetchObjectsListWrap)
        this.getRegion()
    }

    getRegion(callback?: any) { // async
        // Fetch selected region name
        if (!this.regionName || !this.regionId) {
            if (!this.addressData.pr364) {
                console.log('Warn: Cancelled of getting region. Reason: City is null')
                return false
            }
            let selectedRegionRequestData = {
                "ClassList": 
                    [{"Class_id": 21400, "TypeLoad":0, "needLinkObject":1}],
                 "PropertyList": [
                    {"Class_id": 21400, "Property_id": 202}
                ],
                "Filter": [
                    { "Class_id":21400, "Filter_id":1,"Node_id":1,"Property_id":-1,"Operator_id":"=","Value": this.addressData.pr364 }
                ]
            }
            this.fetchObjectsListWrap(
                selectedRegionRequestData,
                (regionResponseData: any)=>{  // success callback func region request
                    this.regionId = regionResponseData['N5'][0].Object_id
                    this.regionName = regionResponseData['N5'][0].CalcName
                    if (callback) callback({regionId: this.regionId, regionName: this.regionName})
                }
            )
        } else
            if (callback) callback({regionId: this.regionId, regionName: this.regionName})
    }
}


export class HomeAddress extends CityAddress {
    streetId = -1
    streetName = ''
    homeNumber = ''
    apartment = ''
    addressType = 'HOME'
    note = ''

    streetsList = []

    addressData: any
    fetchObjectsListWrap: any

    constructor(addressData: any, addressDataNames: Array<any>, fetchObjectsListWrap: any) {
        super(addressData, addressDataNames, fetchObjectsListWrap)
        this.addressData = addressData
        this.fetchObjectsListWrap = fetchObjectsListWrap
        this.streetId = addressData.pr233
        this.homeNumber = addressData.pr234
        if (addressData.pr235)
            this.apartment = addressData.pr235
        this.getStreetName()
        this.note = addressData.pr375
    }

    getStreetName(callback?: any) { // async
        // Fetch selected region name
        if (!this.streetName || !this.streetId) {
            // Fetch street name (object detail tableData)
            if (!this.addressData.pr233) {
                console.log('Street is null');
                return false
            }
            if (!this.addressData.pr364) {
                console.log('Street is null');
                return false
            }
            let selectedStreetRequestData = {
                "ClassList":[{"Class_id":21400,"TypeLoad":0,"needLinkObject":0}],
                "PropertyList":[{"Class_id":21400,"Property_id":205}],
                "PropertyDetailList":[
                    {"Class_id":21400,"PropertyMaster_id":205,"Property_id":-309}, 
                    {"Class_id":21400,"PropertyMaster_id":205,"Property_id":-222}
                ],
                "PropertyDetailFilter": [ // get necessary properties
                    {"Class_id":21400,"Property_id":205,"PropertyDetail_id":-309,"Operator_id":"=","Value": this.addressData.pr233} // value street id
                ], 
                "Filter": [
                    {"Class_id":21400,"Filter_id":1,"Node_id":1,"Property_id":-1,"Operator_id":"=","Value": this.addressData.pr364} // value city id
                ]
            }
            this.fetchObjectsListWrap(
                selectedStreetRequestData,
                (selectedStreetResponseData: any)=>{
                    let streetObj = selectedStreetResponseData['N3021400_0205']
                    this.streetName = streetObj[0].pr_222           
                    if (callback) callback(this.streetName)
                }
            )    

        } else 
            if (callback) callback(this.streetName)
    }
}


export class PostDepartmentAddress extends HomeAddress {
    postDepartmentName!: string
    postDepartmentId: number
    addressType = 'POSTDEPT'
    postDepartmentType = 'NOVA_POSHTA' // postDepartment type
    addressText = ''

    constructor(addressData: any, addressDataNames: Array<any>, fetchObjectsListWrap: any) {
        super(addressData, addressDataNames, fetchObjectsListWrap)
        // Get postDepartment number and name
        // if (addressData.pr363) { // Nova poshta postDept
            this.postDepartmentType = 'NOVA_POSHTA'
            this.postDepartmentId = addressData.pr363
            if (addressData.pr363) {                
                let postDepartmentNameObj = getObjInAr(addressDataNames, 'Object_id', addressData.pr363)
                this.postDepartmentName = postDepartmentNameObj.CalcName
                this.addressText = postDepartmentNameObj.CalcName
            } else
                console.log('Warn: currier office is null')
        // } else { // other localdeliveryCom postDept
        //     this.postDepartmentType = 'OTHER'
        //     this.addressText = addressData.pr_389
        // }
    }
}


export interface ProductParcelI {
    'name': string
    'qty': number
    'cost': number
}



// Open parcel (package) or cargo popup by State_id
export function opentParcelCargoCard(vueRootObj: any, parcelId: number, parcelListItem?: any): any {
    // TODO.optimize: check parcel type (number or obj)
    let parcelItem: any
    if (parcelListItem) parcelItem = parcelListItem // set parcelItem if received
    else { // find parcel in parcelsList
        let packageFound = getObjInAr(appData.sm_parcels_itrade, 'Package_id', parcelId)
        if (packageFound) parcelItem = packageFound
        else console.warn('Can not find package '+ parcelId)
    }
    if (parcelItem.State_id === 5030 || !parcelItem.pr395) // Open parcelCard if divided parcel or pr395 (cargoDetId) not exist
        vueRootObj.$emit(eventsRegister.showPopup, {body: parcelItem, header: i18n.t('parcelPu') +' '+ parcelItem.pr299, type: 'parcel'})
    else // open cargoCard
        vueRootObj.$emit(eventsRegister.showPopup, {body: parcelItem, header: i18n.t('cargoPu') +' '+ parcelItem.pr294, type: 'cargo'})
}